/* Googlefont Poppins CDN Link */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import '~react-pdf/dist/esm/Page/AnnotationLayer.css';

html,
body {
  overflow-x: hidden;
  background-color: #E2E8F0 !important;
  height: 100%;  // Ensure that body and html take full height to enable children to inherit correctly
}

#root {
  height: 100%;  // Ensure the main container/root element also takes full height
}

* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

.bankStatementWrapper {
  height: calc(100vh - 100px);
  overflow: auto;
  background-color: #E2E8F0;
  padding: 1rem 3rem;
  width: 100%;

  @media (max-width:450px) {
    padding: 1rem 1rem;
  }

  .firstRow {
    position: relative;
    display: flex;
    justify-content: space-between;

    @media (max-width: 900px) {
      flex-direction: column;
      gap: 2rem
    }

    .leftSideContent {
      .searchContainer {
        display: flex;
        align-items: center;
        gap: 12px;
        background-color: #FFFFFF;
        border: 1px solid #FFFFFF30;
        border-radius: 8px;
        padding: .7rem 2.5rem;

        input {
          color: #0B2943;
          border: none;
          background-color: transparent;
          outline: none;
        }

      }
    }

    .rightSideContent {
      .rightSideContainer {

        display: flex;
        align-items: center;
        gap: 12px;

        @media (max-width:625px) {
          flex-direction: column;
        }

        span {
          font-size: 16px;
          font-weight: 400;
        }

        .statusSelect {
          padding: .7rem 1.5rem;
          border: 1px solid #FFFFFF30;
          background-color: #FFFFFF;
          border-radius: 8px;
          color: #757575;
          outline: none;
          -moz-appearance: none;
          -webkit-appearance: none;
          appearance: none;
          background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M4.92892 7.05021L3.51471 8.46443L12 16.9497L20.4853 8.46443L19.0711 7.05021L12 14.1213L4.92892 7.05021Z" fill="%23576F83"/></svg>');
          background-repeat: no-repeat;
          background-position: right 0.5rem center;
          background-size: 1.5em;
          padding-right: 2em;
          padding-right: 40px;

          .option {
            background-color: green !important;
          }


        }

        .dateRangeContainer {
          color: #757575 !important;
          display: flex;
          letter-spacing: 1px;
          gap: 12px;
          background-color: #FFFFFF;
          border: 1px solid #FFFFFF30;
          padding: .7rem 2rem;
          border-radius: 8px;

          .date {
            color: #757575;
            position: absolute;
            top: 50px;
            right: 0;
            z-index: 2;
          }

          .statusOptions {
            z-index: 2;
            position: absolute;
            top: 50px;
            background-color: white;
            color: gray;
            border-radius: 5px;
            -webkit-box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.4);
            box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.4);
          }
        }
      }
    }
  }
}

.modal-content {
  background: #FFFFFF;
  border-radius: 10px;

  .modal-header {
    border-bottom: none;
  }

  .modal-backdrop {
    opacity: 0.9;
    background: rgba(2, 26, 46, 0.91);
  }

  .modal-title {

    text-align: start;
    font-weight: 700;
  }

  .firstLabel {
    margin-bottom: 12px;
    color: #5B5B62;
    font-size: 14px;
    font-weight: 500;
  }

  .firstCol,
  .secondCol {
    .colWrapper {
      position: relative;
      padding:
        16px 32px 16px 32px;
      border: 1px solid #E2E8F0;
      display: flex;
      align-items: center;
      border-radius: 8px;
      flex-direction: column;
      gap: 12px;



      span {
        font-weight: 500;
        color: #0B2943;
      }

      .checkBtn {
        position: absolute;
        left: 10px;
        top: 12px
      }

      .selectedCheck {
        background: #E2E8F0;
      }

      img {
        width: fit-content;

        &.statement_icon {
          width: max-content;
          filter: brightness(0) saturate(100%) invert(12%) sepia(22%) saturate(3951%) hue-rotate(182deg) brightness(89%) contrast(93%);
          height: 32px;
        }
      }
    }

  }

  .secondLabel {
    color: #66B8A3;
    font-weight: 700;
    text-decoration: underline;
    font-size: 14px;
    margin: 16px 0 10px 0;
    ;
  }

  .secondRow {
    .dragFileWrapper {
      display: flex;
      justify-content: center;
      gap: 12px;
      border: 2px dotted #0B2943;
      background: rgba(226, 232, 240, 0.23);
      border-radius: 10px;
      padding: 12px;
      width: 100%;
      height: 170px;

      img {
        width: 20px;
      }

      .textWrapper {
        display: flex;
        align-items: center;

        span:nth-child(1) {
          font-family: 500;
        }

        span:nth-child(2) {
          margin-left: 6px;
          font-weight: 800;
          text-decoration: underline;
        }
      }

      .fileUploadedWrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-height: 62px;
        background-color: #fff;
        padding: .7rem .8rem;
        border-radius: 8px;

        .leftSide {
          display: flex;
          align-items: center;
          gap: 12px;

          .filenameWrapper {
            span:nt-child(1) {

              font-weight: 800;
            }
          }
        }

        .rightSide {
          display: flex;
          align-items: center;
          gap: 12px;

          .fileSize {
            border: 1px solid rgba(205, 211, 216, 1);
            border-radius: 2px;
            padding: 1px 6px;
            font-weight: 600;
          }

          .deleteIcon {
            cursor: pointer;
          }
        }
      }


    }
  }

  .addMultipeTextWrapper {
    margin-top: 8px;

    span {
      font-weight: 600;
    }
  }

  .thirdRow {
    .btnWrapper {
      margin-top: 30px;

      button {
        width: 100%;
        background-color: #66B8A3;
        border-radius: 80px;
        padding: 16px 32px 16px 32px;
        color:
          #FFFFFF;
        font-weight: 600;
        font-size: 16px;
        cursor: pointer;
        border: none;
      }

      button:disabled {
        background: #BDBDBD;
        cursor: not-allowed;
      }
    }
  }
}

.loginWrapper {
  display: flex;
  width: 100%;
  min-height: 100vh;
  height: 100%;

  @media (max-width:991px) {
    flex-wrap: wrap;
  }

  .leftSideContainer {
    @media (max-width: 991px) {
      height: 550px;
      width: 100%;
      min-height: 50vh;
    }

    @media (max-width: 767px) {
      height: 400px;
      min-height: unset;
    }

    width: 50%;

    .imageWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;

      .loginLogoWrapper {
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
    }
  }

  .rightSideContainer {
    @media (max-width: 991px) {
      width: 100%;
    }

    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap:20px;
    background: #1D3161;

    form {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: center;
      align-content: center;
      max-width: 710px;
      margin: 0 auto;
      text-align: center;

    }

    .error {
      color: red !important;
    }

    .logoWrapper {
      display: flex;
      justify-content: center;

      img {
        margin-bottom: 45px;
        width: 100%;
        max-width: max-content;
      }
    }

    .loginDataContainer {
      padding: 2rem;
      width: 100%;

      @media (max-width: 768px) {
        padding: 30px;
      }



      h3 {
        text-align: center;
        color: #fff;
        text-transform: uppercase;
        font-family: "Roboto";
        font-size: 40px;
        font-weight: 700;
        line-height: 46.88px;
        position: relative;
        max-width: 458px;
        margin-bottom: 30px;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        display: flex;
        justify-content: center;
        text-align: center;

        @media (max-width: 768px) {
          font-size: 30px;
          font-weight: 700;
          line-height: 36.88px;
        }

        &:before {
          content: "";
          border-top: 1px solid #fff;
          width: 100%;
          height: auto;
          display: block;
          top: 50%;
          position: absolute;
        }

        span {
          color: inherit;
          padding: 10px 24px;
          display: inline;
          background-color: #1d3161;
          z-index: 99;
          position: relative;
          width: max-content;
          text-align: center;

          @media (max-width: 768px) {
            padding: 10px 15px;
          }

        }
      }

      .title.width-516 {
        max-width: 516px
      }

      label {
        color: #fff;
        font-family: "Roboto";
        font-size: 18px;
        font-weight: 700;
        line-height: 21.09px;
        text-align: left;
        margin-bottom: 19px;
        width: max-content;
      }

      .form-group {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;

        input {
          width: auto;
          outline: none;
          font-family: "Roboto";
          font-size: 18px;
          font-weight: 400;
          line-height: 21.09px;
          text-align: left;
          color: #666666;
          width: 100%;
          height: 104.21px;
          border-radius: 10px;
          background: #FFFFFF;
          padding: 30px;

          @media (max-width: 768px) {
            height: 60px;
            padding: 15px;
          }
        }
      }

      input {
        font-size: 16px;
        font-weight: 400;
        color: #0B2943;
        padding: 16px 0px 16px 12px;
        background-color: transparent;
        border: 1px solid rgba(226, 232, 240, 1);
        border-radius: 8px;
      }

      button {
        background-color: #66B8A3;
        color: #fff;
        width: 100%;
        height: 90px;
        border-radius: 20px;
        padding: 20px;
        border: 0px;
        font-family: "Roboto";
        font-size: 30px;
        font-weight: 700;
        line-height: 35.16px;
        text-align: center;

        @media (max-width: 768px) {
          height: 60px;
          font-size: 21px;
          line-height: 25.16px;
          padding: 15px;
        }

        svg {
          stroke: #fff;
        }
      }

      span {
        text-align: end;
        color: #66B8A3;
        font-family: "Roboto";
        width: 100%;
        display: block;
        text-align: left;
      }


      .button_group {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 25px;
        gap: 20px;

        @media (max-width: 1361px) {
          justify-content: center;
        }

        @media (max-width: 768px) {
          justify-content: space-between;
        }

        @media (max-width: 597px) {
          justify-content: center;
        }

        .custom_button {
          border: 1px solid #FFFFFF;
          border-radius: 20px;
          width: 299px;
          height: 69px;
          font-family: "Roboto";
          font-size: 20px;
          font-weight: 700;
          line-height: 23.44px;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          padding: 20px;

          @media (max-width: 768px) {
            width: 259px;
            height: 60px;
            font-size: 18px;
            padding: 15px;
          }

          .logoIcon {
            margin-right: 7px;
            margin-left: auto;
            text-align: unset;
            display: block;
          }

          span {
            color: inherit;
            text-decoration: underline;
            text-align: unset;
            width: max-content;
            margin-right: auto;
          }
        }
      }
    }

  }
}

.dashboardWrapper {
  display: flex;
  width: 100%;

  .headerAndContent {
    margin-left: 280px; // To account for the sidebar width
    width: calc(100% - 280px);
    min-height: 100vh;
    padding: 1rem;  // Optional padding if needed for main content
    // overflow: scroll;
    // overflow-x: hidden;

    @media (max-width:1145px) {
      width: 100%;
      min-height: 100vh;
    }

    .headerWrapper {
      padding: 30px 38px 30px 64px;
      background-color: #E2E8F0;
      display: flex;
      justify-content: space-between;

      @media (max-width:767px) {
        padding: 15px;
        flex-wrap: wrap;
      }

      .leftHeader {
        display: flex;
        align-items: center;

        @media (max-width:1140px) {
          flex: 1;
        }

        @media (max-width:767px) {
          flex: 0 0 100%;
        }

        .headingAndButton {
          display: flex;
          align-items: center;
          gap: 15px;


          @media (max-width:1140px) {
            width: 100%;
          }


          .whenMobile {
            display: none !important;

            @media (max-width:1140px) {
              display: block !important;
              float: right;
              order: 2;
              margin-left: auto;
            }
          }

          button {
            border: 0px;
            width: 47px;
            height: 47px;
            background: #00B296;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              width: 22px;
              width: 20px;
            }
          }

          h3 {
            font-family: Roboto;
            font-size: 40px;
            font-weight: 700;
            line-height: 46.88px;
            text-align: left;
            color: #1D3161;
            margin-bottom: 0px;

            @media (max-width:767px) {
              font-size: 25px;
              line-height: 30px;
            }
          }

          .btnIconWrapper {
            cursor: pointer;

            // margin-top: 13px;
            img {}
          }
        }

      }

      .rightHeader {
        display: flex;
        align-items: center;
        margin-left: 20px;

        @media (max-width:767px) {
          margin-left: auto;
          margin-top: 10px;
        }

        .right_header {
          display: flex;
          align-items: center;
          cursor: pointer;
        }

        .submenu {
          position: absolute;
          right: 0;
          width: max-content;
          list-style: none;
          top: 34px;
          z-index: 11;
          padding: 20px;
          border-radius: 10px;
          background: #FFFFFF;
          border: 1px solid #CDCDCD;
          box-shadow: 0px 4px 14px 0px #0000001A;
          min-width: 217px;

          li:not(:last-child) {
            margin-bottom: 10px;
          }

          .logout {
            &::before {
              content: "";
              background-color: #CDCDCDBD;
              width: 100%;
              height: 1px;
              display: block;
              margin-bottom: 15px;
            }

            span {
              width: 100%;
              height: 40px;
              border-radius: 10px;
              background: #00B296;
              border: 0px;
              font-family: Roboto;
              font-size: 19px;
              font-weight: 600;
              text-align: center;
              color: #fff;
              line-height: 40px;
              cursor: pointer;
              display: block;
            }
          }

          li a {
            padding: 10px;
            display: block;
            text-decoration: unset;
            padding: 15px 15px;
            font-family: Roboto;
            font-size: 15px;
            font-weight: 400;
            line-height: 17.58px;
            text-align: left;
            color: #636363;
          }
        }

        .profileWrapper {
          width: 47px;
          height: 47px;
          background-color: #fff;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 18px;
          overflow: hidden;

          img {
            width: 43px;
            height: 43px;
            object-fit: cover;
            border-radius: 50%;
          }
        }

        h5 {
          margin: 0px;
          font-family: Roboto;
          font-size: 20px;
          font-weight: 700;
          line-height: 25.78px;
          text-align: left;
          color: #1D3161;
        }

        .arrow_icon .icon {
          filter: brightness(0) saturate(100%) invert(14%) sepia(23%) saturate(4231%) hue-rotate(204deg) brightness(95%) contrast(90%);
        }

        .arrow_icon {
          margin-left: 20px;
        }

        select {
          border: none;
          background-color: transparent;
          color: #0B2943;
          font-weight: 700;
          padding-right: 15px;
          outline: none;
        }

        .profileSelect option {
          background: #ffffff !important;
          padding: 0.5rem 1rem !important;
          color: #0b2943 !important;
          font-size: 1rem !important;


        }

      }
    }

  }

}

.contentWrapper {
  height: calc(100vh - 100px);
  overflow: auto;
  background-color: #021A2E;
  padding: 1rem 3rem;

  .firstRow {
    position: relative;
    display: flex;
    justify-content: space-between;

    .leftSideContent {
      .searchContainer {

        display: flex;
        align-items: center;
        gap: 12px;
        background: #0B2943;
        border: 1px solid #FFFFFF30;
        //  border:1px solid black;
        border-radius: 8px;
        padding: 16px 0px 16px 12px;

        input {
          color: #fff;
          border: none;
          background-color: transparent;
          outline: none;
        }

      }
    }

    .rightSideContent {
      .rightSideContainer {

        display: flex;
        align-items: center;
        gap: 12px;


        span {
          font-size: 16px;
          font-weight: 400;
          // color: #fff;
        }

        .statusSelect {
          padding: 16px 20px 16px 20px;
          border: 1px solid #FFFFFF30;
          background: #0B2943;
          border-radius: 8px;
          color:
            #FFFFFF;

          -moz-appearance: none;
          -webkit-appearance: none;
          appearance: none;
          background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M4.92892 7.05021L3.51471 8.46443L12 16.9497L20.4853 8.46443L19.0711 7.05021L12 14.1213L4.92892 7.05021Z" fill="%231C8EDA"/></svg>');
          background-repeat: no-repeat;
          background-position: right 0.5rem center;
          background-size: 1.5em;
          padding-right: 2em;
          padding-right: 40px;

        }

        .dateRangeContainer {
          display: flex;
          letter-spacing: 1px;
          gap: 12px;
          background:
            #0B2943;
          border: 1px solid #FFFFFF30;
          padding: 16px 20px 16px 20px;
          border-radius: 8px;

          .date {
            position: absolute;
            top: 50px;
            right: 0;
            z-index: 2;
          }

          .options {
            z-index: 2;
            position: absolute;
            top: 50px;
            background-color: white;
            color: gray;
            border-radius: 5px;
            -webkit-box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.4);
            box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.4);
          }

        }

        select {
          // padding: 16px 0px 16px 12px;
          // border: 1px solid #FFFFFF30;
          // background: #0B2943;
          // border-radius: 8px;
          // color: 
          // #FFFFFF;

        }

        input {
          // border: 1px solid #FFFFFF30;
          // background: #0B2943;
          // padding: 16px 0px 16px 12px;
          // border-radius:8px;
          // color: 
          // #FFFFFF;
          // border: none;
        }

        // input[type="date"]::-webkit-calendar-picker-indicator {
        //     filter: invert(1); /* Invert the color of the icon */
        //   }
        //   input[type="date"]::-ms-calendar {
        //     filter: invert(1); /* Invert the color of the icon */
        //   }
      }

    }
  }
}

.tableMain {
  padding: 2rem 0;

  .tableWrapper {
    padding: 1rem 0;
    border-radius: 8px;
    font-size: 16px;
    background-color: #ffffff;
  }
}

.rdt_TableHeadRow {
  font-size: 16px !important;
}

.rdt_TableBody {
  .rdt_TableCell {
    padding-inline: 1rem;

    .file_name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.table-wrapper {
  height: calc(100vh - 107px);
  // overflow: auto;
  background-color: #E2E8F0;
  padding: 30px 38px 37px 64px;
  width: 100%;

  @media (max-width:767px) {
    padding: 10px 15px 20px;
    // max-height: 100vh;
    height: max-content;
  }

  .tableMain {
    border-radius: 20px 20px 0px 0px;
    overflow: hidden;
    height: 100%;
    padding: 0px;

    .pdf_icon img {
      width: 40px;
      margin-right: 15px;
      height: 40px;
    }

    .progressbar {
      font-size: 12px;
    }

    .progressbar .bar {
      width: 100px;
      height: 10px;
      display: block;
      background-color: #dcdcdc;
      border-radius: 10px;
      margin-bottom: 5px;
      position: relative;
      overflow: hidden;

      &.Processing:before {
        content: "";
        width: 60%;
        display: block;
        background-color: #ffac30;
        height: 100%;
        border-radius: 10px;
      }

      &.Cancelled:before {
        content: "";
        width: 100%;
        display: block;
        background-color: #d91010;
        height: 100%;
        border-radius: 10px;
      }

      &.Completed:before {
        content: "";
        width: 100%;
        display: block;
        background-color: #008d77;
        height: 100%;
        border-radius: 10px;
      }

      &.Available:before {
        content: "";
        width: 100%;
        display: block;
        background-color: #008d77;
        height: 100%;
        border-radius: 10px;
      }
    }

    &.cases_management {
      height: calc(100% - 72px);
    }

    .text-center.note {
      font-family: Roboto;
      font-size: 15px;
      font-weight: 400;
      margin-top: 15px;
      color: #1D3161;
      width: 100%;
    }

    .tableWrapper {
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      padding: 0px;
      background-color: transparent;
      border-radius: 0px;
      border-radius: 20px 20px 0px 0px;

      .rdt_TableCol,
      .rdt_TableCell {
        padding-left: 37px;
        padding-top: 30px;
        padding-bottom: 30px;
        padding-right: 30px;

        @media (max-width:1400px) {
          &:last-child {
            min-width: 200px;
          }
        }

        @media (max-width:991px) {
          padding-left: 20px;
          padding-top: 20px;
          padding-bottom: 20px;
          padding-right: 20px;
        }

        @media (max-width:767px) {
          padding-left: 15px;
          padding-top: 15px;
          padding-bottom: 20px;
          padding-right: 20px;


        }
      }

      .paginationWrapper {
        margin-top: auto;
        margin-left: auto;
        padding: 0px;

        @media (max-width:767px) {
          margin-top: 30px;
        }

        @media (max-width:400px) {
          margin-right: auto;
        }

        .btnWrapper {
          font-family: Roboto;
          font-size: 15px;
          font-weight: 700;
          line-height: 17.58px;
          text-align: left;
          color: #636363;
          gap: 5px;

          @media (max-width:400px) {
            flex-wrap: wrap;
            justify-content: center;
            text-align: center;
          }

          div {
            @media (max-width:400px) {
              width: 100%;
              margin-bottom: 10px;

            }
          }

          #pageSize {
            width: 53px;
            height: 23px;
            border-radius: 10px;
            border: 1px solid #CDCDCD;
            box-shadow: 0px 4px 14px 0px #0000001A;
            background-color: #fff;
            padding-left: 8px;
            font-family: Roboto;
            font-size: 15px;
            font-weight: 400;
            line-height: 17.58px;
            text-align: left;
            color: #636363;
            margin-left: 12px;
            background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAHCAYAAAA8sqwkAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACsSURBVHgBhZDNCQJBDIWTHbx48brgD5YQ7MBKdrABt4S1ArUCZyoQb95sITUowhxX8SZMnAjCsqyaW/K+F16C49liHWP0V3YMP2pIljKAIjMJzhD3OdnpN1g1ZQyAN3XgMMjpZhB3/ZwOj8B1G+4hnkBkdWZ3NDq8B+YuUxO+sHM6w+a2CdlSEJdPkbn2CkeRbbpv82GwnXdEtkLE4i2K+BSjgn+lpvSVskt7AQCNSADXuRoMAAAAAElFTkSuQmCC");
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            background-repeat: no-repeat;
            background-position: 86% 50%;
          }

          .total_pagination_text {
            margin-left: 5px;
            margin-right: 15px;
            font-family: Roboto;
            font-size: 15px;
            font-weight: 700;
            line-height: 17.58px;
            text-align: left;

            @media (max-width:400px) {
              width: 100%;
              margin-bottom: 10px;
              text-align: center;
            }
          }

          button {
            background-color: transparent;
            font-family: Roboto;
            font-size: 15px;
            font-weight: 400;
            line-height: 17.58px;
            text-align: left;
            color: #636363;
            width: 12.53px;
            height: 24px;
            text-align: center;
            padding: 0px;

            &.active {
              width: 23.53px;
              height: 24px;
              background-color: #00B296;
              color: #fff;
              border-radius: 50%;
            }

            &.prev {
              width: 23.53px;
              height: 24px;
              border-radius: 6px;
              background-color: #fff;

              img {
                transform: rotate(90deg);
              }
            }

            &.next {
              width: 23.53px;
              height: 24px;
              border-radius: 6px;
              background-color: #fff;

              img {
                transform: rotate(-90deg);
              }
            }
          }

          button[disabled=""] {
            opacity: 0.4;
          }
        }
      }

      .rdt_TableBody {
        height: 100vh;
        overflow: auto;
        background-color: #e2e8f0;

        &::-webkit-scrollbar {
          height: 5px;
          width: 5px;
          background: #dedede;
        }

        &::-webkit-scrollbar-thumb {
          background: #1d3161;
          -webkit-border-radius: 1ex;
          -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
        }

        &::-webkit-scrollbar-corner {
          background: #dedede;
          height: 30px;
          margin-right: 10px;
        }
      }

      .sc-koYCI {
        display: block;
        height: 100%;

        @media (max-width:1400px) {
          width: 1400px;
        }
      }

      .sc-jsJARu {
        max-height: calc(100% - 99px);

        @media (max-width:767px) {
          // overflow-y: auto;
          height: max-content;

          &::-webkit-scrollbar {
            height: 5px;
            width: 5px;
            background: #dedede;
          }

          &::-webkit-scrollbar-thumb {
            background: #1d3161;
            -webkit-border-radius: 1ex;
            -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
          }

          &::-webkit-scrollbar-corner {
            background: #dedede;
            height: 30px;
            margin-right: 10px;
          }
        }
      }

      .rdt_TableRow {
        border: 1px solid transparent;
        transition: all 0.5s ease;
        outline: unset;
        min-height: unset;

        .rdt_TableCell {
          font-family: Roboto;
          font-size: 14px;
          font-weight: 400;
          line-height: 16.41px;
          text-align: left;
          color: #636363;
          transition: all 0.5s ease;
        }

        &:nth-child(odd) {
          background: #F2F2F2;
        }

        &:hover {
          background: #3AABFF;
          border: 1px solid #3AABFF !important;
          box-shadow: 0px 4px 44px 0px #3AABFF66;
          // cursor: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAbCAYAAACN1PRVAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAC7SURBVHgB7dJREcIwEATQjYNKqAQkVAIOWgk4QEKRgINKqARwUAmVsFyYMKSBEpoLw0/eTL6S3M5dAhRF8Vck97JmWQN+TUJGPvUrZ2q7587WSCWXD1xqg/2j6/zhAg0pcPaKza6TRtbE93pNWBUUnhnXIpVc3n0ZspgAUvH1/WJGaEiB04Yw9WcJ3281SDVGL7CLBNnuK6RyHQ38/EnsXgctxj/HlGVsLqxhhrEZbOR1cA8wxlxRFDncAEF8FnzKnEe7AAAAAElFTkSuQmCC"), auto;
          cursor: pointer;
          .rdt_TableCell {
            color: #fff;
          }
        }
      }

      .rdt_TableHead {
        font-family: Roboto;
        font-size: 18px;
        font-weight: 600;
        line-height: 21.09px;
        text-align: left;
        color: #FFFFFF;

        svg path {
          fill: #fff !important;
        }

        @media (max-width:991px) {
          min-height: 67px;
        }

        @media (max-width:767px) {
          position: sticky;
          top: 0;
          z-index: 11;
        }

        * {

          font-family: Roboto;
          font-size: 18px;
          font-weight: 600;
          line-height: 21.09px;

        }
      }

      .rdt_TableHeadRow {
        background-color: #1D3161;
        border: 0px;


      }
    }
  }

  .action_button {
    display: flex;
    flex-wrap: wrap;

    @media (max-width:767px) {
      flex-wrap: nowrap;
      width: 200px;
    }

    span.btn {
      opacity: 0.45;
    }

    .btn {
      width: 37px;
      height: 37px;
      border-radius: 50%;
      border: 1px solid #1d3161;
      background-color: #fff;
      margin-right: 5px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px;
    }

    img {
      filter: brightness(0) saturate(100%) invert(15%) sepia(17%) saturate(4765%) hue-rotate(198deg) brightness(98%) contrast(91%);
    }
  }

  @media (max-width:450px) {
    padding: 1rem 1rem;
  }

  .firstRow {
    position: relative;
    display: flex;
    justify-content: space-between;

    @media (max-width: 900px) {
      flex-direction: column;
      gap: 2rem
    }

    .leftSideContent {
      .searchContainer {

        display: flex;
        align-items: center;
        gap: 12px;
        //  background: #0B2943;
        background-color: #FFFFFF;
        border: 1px solid #FFFFFF30;
        //  border:1px solid black;
        border-radius: 8px;
        //  padding: 16px 0px 16px 12px;
        padding: .7rem 2.5rem;

        input {
          // color: #fff;
          color: #0B2943;
          border: none;
          background-color: transparent;
          outline: none;
        }

      }
    }

    .rightSideContent {
      .rightSideContainer {

        display: flex;
        align-items: center;
        gap: 12px;

        @media (max-width:625px) {
          flex-direction: column;
        }

        span {
          font-size: 16px;
          font-weight: 400;
          // color: #fff;
        }

        .statusSelect {
          // padding: 16px 20px 16px 20px;
          padding: .7rem 1.5rem;
          border: 1px solid #FFFFFF30;
          // background: #0B2943;
          background-color: #FFFFFF;
          border-radius: 8px;
          color: #757575;
          outline: none;

          -moz-appearance: none;
          -webkit-appearance: none;
          appearance: none;
          background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M4.92892 7.05021L3.51471 8.46443L12 16.9497L20.4853 8.46443L19.0711 7.05021L12 14.1213L4.92892 7.05021Z" fill="%23576F83"/></svg>');
          background-repeat: no-repeat;
          background-position: right 0.5rem center;
          background-size: 1.5em;
          padding-right: 2em;
          padding-right: 40px;

          .option {
            background-color: green !important;
          }


        }

        .dateRangeContainer {
          color: #757575 !important;
          display: flex;
          letter-spacing: 1px;
          gap: 12px;
          background-color: #FFFFFF;
          border: 1px solid #FFFFFF30;

          // padding: 16px 20px 16px 20px;   
          padding: .7rem 2rem;
          border-radius: 8px;

          .date {
            color: #757575;
            position: absolute;
            top: 50px;
            right: 0;
            z-index: 2;
          }

          .statusOptions {
            z-index: 2;
            position: absolute;
            top: 50px;
            background-color: white;
            color: gray;
            border-radius: 5px;
            -webkit-box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.4);
            box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.4);
          }

        }


      }

    }
  }
}

.paginationWrapper {
  padding: 1rem 0;

  .btnWrapper {
    @media (max-width:430px) {
      display: flex;
      flex-wrap: wrap;
    }

    display: flex;
    justify-content: center;
    align-items: center;
    gap:12px;

    button {
      @media (max-width:700px) {
        padding: .4rem 1rem;
      }

      @media (max-width:530px) {
        padding: .4rem .7rem;
      }

      background-color: #fff;
      padding: .4rem 1.8rem;
      border-radius: 10px;
      border: none;
      font-weight: 600;

      &.active {
        background-color: #E2E8F0;
      }
    }
  }
}

.detailedTableWrapper {
  background-color: #e2e8f0;
  height: calc(100vh - 100px);
  overflow: auto;

  justify-content: center;
  overflow: auto;
  flex-direction: column;

  .innerDetailedTableWrapper {
    overflow: auto;
    padding: 1rem;
  }

  input {
    padding: 8px;
    margin-block: 15px;
  }

  ::-webkit-scrollbar {
    height: 12px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
  }

  ::-webkit-scrollbar-thumb {
    background: grey;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: grey;
  }

  .detailedTableWrapper .innerDetailedTableWrapper {
    border-radius: 5px;
    overflow: hidden;
  }

  .rdt_TableHeadRow {
    font-size: 16px !important;
  }

  .rdt_TableBody {
    .rdt_TableCell {
      padding-inline: 1rem;

      .file_name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .DetailedBtnWrapper {
    display: flex;
    justify-content: end;
    gap: 12px;
    margin-top: 12px;
    padding: 1rem;

    button {
      padding: 0.5rem 2rem;
      font-size: 16px;
      color: #ffffff;
      border: none;
      font-weight: 500;
      border-radius: 12px;
      background: linear-gradient(182.34deg, #102e61 2.19%, #0e254d 98.31%);
      cursor: pointer;
    }
  }
}

.staticTableWrapper {
  height: calc(100vh - 100px);
  overflow: auto;
  padding: 1rem 2rem;
  width: 100%;
  background-color: #e2e8f0;
  ;

  h2 {
    text-align: center;
    color: rgb(140, 138, 138);
    margin-bottom: 1rem;
  }

  .innerExtractionMainTableWrapper {
    background-color: #fff;
    border-radius: 8px;
    font-size: 16px;
    padding: 1rem 0;
    font-weight: 700;

  }
}

.rdt_TableHeadRow {
  font-size: 16px !important;
}

.rdt_TableBody {
  .rdt_TableCell {
    padding-inline: 1rem;

    .file_name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.wrapper {
  display: flex;
  min-height: 100vh;  // Ensure the main wrapper/container takes up full viewport height
}

.sidebarWrapper {
  height: 100vh;  // Force the sidebar to extend the entire viewport height
  width: 280px;
  background: #1D3161;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  position: fixed;  // Fix to ensure it stays at the left
  top: 0;
  left: 0;

  * {
    font-family: "Roboto";
  }

  @media (max-width: 1140px) {
    display: none;
  }

  .logoAnduploadBtnWrapper {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    padding-left: 29px;
    padding-right: 29px;

    button {
      background-color: #66b8a3;
      cursor: pointer;
      border: none;
      border-radius: 80px;
      font-size: 16px;
      font-weight: 600;
      color: #fff;
      padding: 0.7rem 3rem;
    }
  }

  .optionsWrapper {
    color: #fff;
    display: flex;
    margin-top: 39px;
    width: 100%;

    ul {
      margin: 0;
      cursor: pointer;
      padding-left: 0px;
      width: 100%;

      li {
        text-transform: capitalize;
        margin: 0;
        display: flex;
        gap: 15px;
        font-size: 14px;
        font-weight: 400;
        list-style: none;

        a {
          display: flex;
          align-items: center;
          color: inherit;
          text-decoration: none;
          font-family: "Roboto";
          font-size: 19px;
          font-weight: 400;
          line-height: 15px;
          text-align: left;
          text-transform: uppercase;
          padding: 7px 29px;
          width: 100%;
          margin-bottom: 7px;

          .icon {
            margin-right: 15px;
            filter: brightness(0) invert(1);
            width: 32px;
            height: 26px;
            object-fit: contain;
            transition: all 0.5s ease;
          }

          &:hover,
          &.active {
            background-color: #fff;
            color: #1D3161;

            .icon,
            .icon {
              filter: brightness(0) saturate(100%) invert(15%) sepia(17%) saturate(4765%) hue-rotate(198deg) brightness(98%) contrast(91%);
            }
          }
        }
      }
    }
  }

  .bottomMenu {
    color: #fff;
    margin-top: auto;
    margin-bottom: 21px;

    button {
      border: 0px;
      background-color: transparent;
      text-decoration: none;
      font-family: "Roboto";
      font-size: 19px;
      font-weight: 400;
      line-height: 15px;
      text-align: left;
      text-transform: uppercase;
      padding: 7px 29px;
      width: 100%;
      margin-bottom: 7px;
      color: #fff;
      display: flex;
      align-items: center;

      &.setting-menu.d-show {
        background-color: #3AABFF !important;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        margin-bottom: 0px;
        z-index: 2;
        position: relative;
        padding-top: 16px;
        padding-bottom: 10px;
        // padding-left: 18px;
        // padding-right: 13px;
        font-family: "Roboto";


        // .icon {
        //   width: 28px;
        //   height: 28px;
        //   margin-right: 14px;
        // }

        span.arrow_icon {
          // margin-top: -8px;

          .icon {
            width: 14px;
            height: 15px;
            margin: 0px;
          }
        }

      }

      &:last-child {
        margin-bottom: 0px;
      }

      span.arrow_icon {
        float: right;
        margin-left: auto;

        .icon {
          width: 14px;
          height: 15px;
          margin: 0px;
        }
      }

      img.icon {
        margin-right: 15px;
        filter: brightness(0) invert(1);
        width: 33px;
        height: 32px;
        object-fit: cover;
        transition: all 0.5s ease;
      }
    }

    .settingsub-menu {
      &.d-hide {
        ul {
          display: none;
        }
      }
    }


    .settingsub-menu.d-show {
      background-color: #eaeff7;
      padding-top: 15px;
      margin-top: -15px;
      margin-bottom: 7px;

      li {
        a {
          font-family: "Roboto";
          font-size: 16px;
          font-weight: 400;
          text-align: left;
          padding: 0px 29px;
          width: 100%;
          color: #1D3161;
          text-decoration: unset;
          padding-left: 29px;
          padding-right: 29px;
          padding-top: 8px;
          padding-bottom: 8px;
          display: flex;
          align-items: center;
          text-transform: uppercase;

          &:hover,
          &.active {
            background: #1D3161;
            color: #3AABFF;

            .icon {
              filter: brightness(0) saturate(100%) invert(56%) sepia(31%) saturate(2106%) hue-rotate(181deg) brightness(102%) contrast(101%);
            }
          }

          .icon {
            width: 28px;
            height: 28px;
            margin-right: 14px;
            object-fit: contain;
            filter: brightness(0) saturate(100%) invert(15%) sepia(17%) saturate(4765%) hue-rotate(198deg) brightness(98%) contrast(91%);
          }
        }

        &:last-child {
          a {
            margin-bottom: 0px;
          }
        }
      }
    }

    ul {
      margin: 0;
      cursor: pointer;
      padding: 0px;

      li {
        text-transform: capitalize;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
        font-size: 14px;
        font-weight: 400;
        list-style: none;
      }

    }
  }

}

.sidebarWrapperMobile {
  display: none;
  background-color: #0E254D !important;
}

.offcanvasWrapper {
  max-width: 300px !important;
  height: 100vh;
  background: #1D3161 !important;
  display: flex !important;
  flex-wrap: nowrap;
  overflow: scroll;

  * {
    font-family: "Roboto";
  }

  .offcanvas-header {
    margin-top: 22px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 0px;

    .offcanvas-title.h5 img {
      width: 66%;
    }

    .btn-close {
      padding: calc(var(--bs-offcanvas-padding-y) * .5) calc(var(--bs-offcanvas-padding-x) * .5);
      margin: calc(-.5 * var(--bs-offcanvas-padding-y)) calc(-.5 * var(--bs-offcanvas-padding-x)) calc(-.5 * var(--bs-offcanvas-padding-y)) auto;
      filter: brightness(0) invert(1);
      opacity: 1;
    }
  }

  .offcanvas-body {
    padding: 0px;
    display: flex;
    flex-wrap: wrap;
  }

  .optionsWrapper {
    color: #fff;
    display: flex;
    margin-top: 10px;
    width: 100%;

    ul {
      margin: 0;
      cursor: pointer;
      padding: 0px;
      width: 100%;

      li {
        text-transform: capitalize;
        margin: 0;
        display: flex;
        gap: 12px;

        a {
          display: flex;
          align-items: center;
          color: inherit;
          text-decoration: none;
          font-family: "Roboto";
          font-size: 17px;
          font-weight: 400;
          line-height: 15px;
          text-align: left;
          text-transform: uppercase;
          padding: 13px 20px;
          width: 100%;
          margin-bottom: 0px;

          .icon {
            margin-right: 13px;
            filter: brightness(0) invert(1);
            width: 30px;
            height: 24px;
            object-fit: contain;
            transition: all 0.5s ease;
          }

          &:hover,
          &.active {
            background-color: #fff;
            color: #1D3161;

            .icon,
            .icon {
              filter: brightness(0) saturate(100%) invert(15%) sepia(17%) saturate(4765%) hue-rotate(198deg) brightness(98%) contrast(91%);
            }
          }
        }
      }
    }
  }

  .bottomMenu {
    color: #fff;
    margin-top: auto;
    margin-bottom: 20px;
    width: 100%;

    button {
      border: 0px;
      background-color: transparent;
      text-decoration: none;
      font-family: "Roboto";
      font-size: 17px;
      font-weight: 400;
      line-height: 15px;
      text-align: left;
      text-transform: uppercase;
      padding: 13px 20px;
      width: 100%;
      color: #fff;
      display: flex;
      align-items: center;

      &.setting-menu.d-show {
        background-color: #3AABFF !important;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        margin-bottom: 0px;
        z-index: 2;
        position: relative;
        padding-top: 16px;
        padding-bottom: 10px;
        // padding-left: 18px;
        // padding-right: 13px;

        .icon {
          // width: 28px;
          // height: 28px;
          // margin-right: 14px;
        }

        span.arrow_icon {
          // margin-top: -8px;

          .icon {
            width: 14px;
            height: 15px;
            margin: 0px;
          }
        }

      }

      &:last-child {
        margin-bottom: 0px;
      }

      span.arrow_icon {
        float: right;
        margin-left: auto;

        .icon {
          width: 14px;
          height: 15px;
          margin: 0px;
        }
      }

      img.icon {
        margin-right: 13px;
        filter: brightness(0) invert(1);
        width: 30px;
        height: 30px;
        object-fit: cover;
        transition: all 0.5s ease;
      }
    }

    .settingsub-menu {
      &.d-hide {
        ul {
          display: none;
        }
      }
    }


    .settingsub-menu.d-show {
      background-color: #eaeff7;
      padding-top: 15px;
      margin-top: -15px;
      margin-bottom: 7px;

      li {
        a {
          font-family: "Roboto";
          font-size: 16px;
          font-weight: 400;
          text-align: left;
          padding: 0px 29px;
          width: 100%;
          color: #1D3161;
          text-decoration: unset;
          padding-left: 18px;
          padding-right: 13px;
          padding-top: 7px;
          padding-bottom: 7px;
          display: flex;
          align-items: center;
          text-transform: uppercase;

          &:hover,
          &.active {
            background: #1D3161;
            color: #3AABFF;

            .icon {
              filter: brightness(0) saturate(100%) invert(56%) sepia(31%) saturate(2106%) hue-rotate(181deg) brightness(102%) contrast(101%);
            }
          }

          .icon {
            width: 28px;
            height: 28px;
            margin-right: 14px;
            object-fit: contain;
            filter: brightness(0) saturate(100%) invert(15%) sepia(17%) saturate(4765%) hue-rotate(198deg) brightness(98%) contrast(91%);
          }
        }

        &:last-child {
          a {
            margin-bottom: 0px;
          }
        }
      }
    }

    ul {
      margin: 0;
      cursor: pointer;
      padding: 0px;

      li {
        text-transform: capitalize;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
        font-size: 14px;
        font-weight: 400;
        list-style: none;
      }

    }
  }

  .btn-close {
    color: #fff;
  }

  .offcanvas-close {
    position: absolute !important;
    top: 10px !important;
    right: 10px !important;
    cursor: pointer !important;
    color: red; // Change color to red or any color you prefer
  }
}

.wrapper-nfunt {
  min-height: 90vh;
  margin: auto;
  align-content: center;
  text-align: center;

  h1 {
    color: #3C3C3C30;
    font-family: "Poppins", Sans-serif;
    font-size: 200px;
    font-weight: 600;
  }
}

.section.filter {
  padding: 0px;
  margin-bottom: 10px;

  .search_box {
    position: relative;
    max-width: 424.99px;

    @media (max-width:767px) {
      margin-bottom: 20px;
      width: 100%;
    }

    input {
      width: 100%;
      height: 55px;
      border-radius: 10px;
      background: #FFFFFF;
      border: 1px solid #CDCDCD;
      box-shadow: 0px 4px 14px 0px #0000001A;
      outline: none;
      padding: 10px 15px;
      font-family: Roboto;
      font-size: 15px;
      font-weight: 400;
      line-height: 17.58px;
      text-align: left;

      @media (max-width:767px) {
        width: 100%;
      }
    }

    .search_icon {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .row {
    display: flex;
    align-items: flex-end;
  }

  .fiter_box {
    margin-left: auto;
    flex-wrap: wrap;
    justify-content: flex-end;
    display: flex;
    align-items: center;
    position: relative;

    .dateRangeContainer {
      color: #757575 !important;
      letter-spacing: 1px;
      gap: 12px;
      background-color: #FFFFFF;
      padding: 10px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      border: 1px solid #CDCDCD;
      box-shadow: 0px 4px 14px 0px #0000001A;
      height: 55px;

      &>span {
        font-family: Roboto;
        font-size: 15px !important;
        font-weight: 400 !important;
        line-height: 17.58px;
        text-align: left;
        color: #636363 !important;
      }

      .calender_icon {
        background-color: #929292;
        width: 20px;
        height: 22px;
        border-radius: 0px;

        img {
          margin-left: auto;
          margin-right: auto;
          width: 100%;
          height: 23px;
        }
      }
    }

    #caseId,
    #statementtype,
    #status {
      margin-right: 13px;
    }

    &>select {
      width: 148px;
      height: 55px;
      border-radius: 10px;
      background: #FFFFFF;
      border: 1px solid #CDCDCD;
      box-shadow: 0px 4px 14px 0px #0000001A;
      padding: 10px;
      font-family: Roboto;
      font-size: 15px;
      font-weight: 400;
      line-height: 17.58px;
      text-align: left;
      color: #636363;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAHCAYAAAA8sqwkAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACsSURBVHgBhZDNCQJBDIWTHbx48brgD5YQ7MBKdrABt4S1ArUCZyoQb95sITUowhxX8SZMnAjCsqyaW/K+F16C49liHWP0V3YMP2pIljKAIjMJzhD3OdnpN1g1ZQyAN3XgMMjpZhB3/ZwOj8B1G+4hnkBkdWZ3NDq8B+YuUxO+sHM6w+a2CdlSEJdPkbn2CkeRbbpv82GwnXdEtkLE4i2K+BSjgn+lpvSVskt7AQCNSADXuRoMAAAAAElFTkSuQmCC);
      appearance: none;
      background-repeat: no-repeat;
      background-position: 92% 52%;
      padding-right: 30px;
    }

    .rdrCalendarWrapper {
      position: absolute;
      top: 70px;
      right: 0;
      z-index: 999;

      @media (max-width:767px) {
        width: 291px;

        .rdrMonth {
          width: 291px;
        }
      }
    }

    .title {
      margin-right: 19px;
      font-family: Roboto;
      font-size: 18px !important;
      font-weight: 500;
      line-height: 21.09px;
      text-align: left;
      color: #1D3161 !important;

      // @media (max-width:767px) {
      //   width: 100%;
      //   margin-right: 0px;
      //   margin-bottom: 10px;
      // }

      &~span:not(.calender_icon) {
        font-family: Roboto;
        font-size: 15px !important;
        line-height: 17.58px;
        text-align: left;
        color: #636363 !important;
        margin-right: 9px;
      }
    }

    span.calender_icon {
      width: 62px;
      height: 62px;
      background: #00B296;
      border-radius: 50%;
      text-align: center;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-right: 0px;

      img {
        width: 32px;
        height: 32px;
      }
    }

    // 
  }
}


.main_user_form {
  min-height: calc(100vh - 107px);
  overflow: auto;
  background-color: #E2E8F0;
  padding: 30px 38px 37px 64px;
  width: 100%;

  @media (max-width:767px) {
    padding: 10px 15px 20px;
    height: max-content;
  }

  .text-center.main_title {
    padding-left: 30px;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-right: 30px;
    background-color: #1D3161;
    color: #fff;
    border-radius: 20px 20px 0px 0px;

    @media (max-width:991px) {
      padding-left: 20px;
      padding-top: 20px;
      padding-bottom: 20px;
      padding-right: 20px;
    }

    @media (max-width:767px) {
      padding-left: 15px;
      padding-right: 15px;
    }

    h2 {
      margin-bottom: 0px;
      line-height: 27px;
      font-family: "Roboto";
      font-size: 30px;
      font-weight: 700;
      color: #FFFFFF;

      @media (max-width:767px) {
        font-size: 20px;
      }
    }


  }


  .custom_from {
    background-color: #fff;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 15px;
    padding-right: 15px;

    @media (max-width:767px) {
      padding: 30px 0px;
    }

    .form-group {
      margin-bottom: 17px;
      position: relative;

      .rdrCalendarWrapper {
        position: absolute;
        bottom: 0;
        left: -12px;
        z-index: 999;


        @media (max-width:767px) {
          width: 291px;

          .rdrMonth {
            width: 291px;
          }
        }
      }

      input.form-input,
      select.form-input,
      textarea.form-input {
        width: 100%;
        height: 75px;
        border-radius: 10px;
        font-family: Roboto;
        font-size: 18px;
        font-weight: 400;
        line-height: 21.09px;
        text-align: left;
        color: #666666;
        border: 0px;
        background-color: #F5F5F5;
        padding: 10px 30px;

        @media (max-width:767px) {
          height: 60px;
          font-size: 16px;
          padding: 10px 20px;
        }
      }

      textarea.form-input {
        resize: unset;
        padding-top: 25px;
        padding-bottom: 25px;

        &.edit {
          height: 150px;
          padding-top: 30px;
          padding-bottom: 30px;
        }
      }

      select {
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAHCAYAAAA8sqwkAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACsSURBVHgBhZDNCQJBDIWTHbx48brgD5YQ7MBKdrABt4S1ArUCZyoQb95sITUowhxX8SZMnAjCsqyaW/K+F16C49liHWP0V3YMP2pIljKAIjMJzhD3OdnpN1g1ZQyAN3XgMMjpZhB3/ZwOj8B1G+4hnkBkdWZ3NDq8B+YuUxO+sHM6w+a2CdlSEJdPkbn2CkeRbbpv82GwnXdEtkLE4i2K+BSjgn+lpvSVskt7AQCNSADXuRoMAAAAAElFTkSuQmCC);
        appearance: none;
        background-repeat: no-repeat;
        background-position: 96% 52%;
        margin-right: 12px;
      }

      .error {
        color: red !important;
        font-family: "Roboto";
        width: 100%;
        display: block;
        text-align: left;
      }

      label {
        display: block;
        width: 100%;
        margin-bottom: 10px;
        font-family: Roboto;
        font-size: 18px;
        font-weight: 700;
        line-height: 21.09px;
        text-align: left;
        color: #151515;

        @media (max-width:767px) {

          font-size: 16px;
        }

        .optional {
          font-family: Roboto;
          font-size: 18px;
          font-weight: 400;
          line-height: 21.09px;
          text-align: left;
          color: #151515;
        }
      }
    }

    .btn.btn-primary {
      width: 100%;
      height: 66px;
      border-radius: 10px;
      background: #00B296;
      border: 0px;
      outline: 0px;
      font-family: Roboto;
      font-size: 24px;
      font-weight: 700;
      line-height: 28.13px;
      text-align: center;
      color: #FFFFFF;
      margin-top: 5px;

      @media (max-width:767px) {
        font-size: 19px;
        height: 60px;
      }

      svg {
        stroke: #fff;
      }
    }

    .mb-3.nav.nav-tabs.nav-justified {
      margin-bottom: 30px !important;
      justify-content: center;
      border: 0px;
      gap: 0px;
      margin-top: -30px;

      @media (max-width:767px) {
        margin-top: 0px;
        gap: 15px;
      }

      .nav-item {
        padding: 0px;
        flex: unset;
        margin-right: 30px;

        @media (max-width:767px) {
          margin-right: 0px;
        }

        &:last-child {
          margin: 0px;
        }

        .nav-link {
          font-size: 22px;
          font-weight: 600;
          line-height: 25.78px;
          text-align: left;
          border: 0px;
          padding: 7px 20px;
          color: #1D3161;
          border-radius: 10px;
          position: relative;
          transition: all 0.5s ease;

          @media (max-width:767px) {
            font-size: 18px;
            padding: 7px 14px;
          }

          &.active {
            color: #fff;
            background-color: #3AABFF;

            &:before {
              border-top: 14px solid #3AABFF;
            }
          }



          &:before {
            content: "";
            position: absolute;
            width: 0;
            border-top: 14px solid #fff;
            border-left: 14px solid transparent;
            border-right: 14px solid transparent;
            bottom: -9px;
            left: 0;
            right: 0;
            text-align: center;
            margin: 0 auto;
            transition: all 0.5s ease;
          }
        }
      }
    }
  }
}


.deletewrapper {
  .modal-title.h4 {
    font-family: Roboto;
    font-size: 30px;
    font-weight: 700;
    line-height: 35.16px;
    text-align: left;
    color: #1D3161;
  }

  .btn.btn-danger {
    min-width: 133.33px;
    height: 40px;
    border-radius: 10px;
    border: 0px;
    font-family: Roboto;
    font-weight: 400;
    line-height: 21.09px;
  }

  .btn.btn-info {
    min-width: 133.33px;
    height: 40px;
    border-radius: 10px;
    border: 1px solid #858585;
    background-color: transparent;
    font-family: Roboto;
    font-weight: 400;
    color: #858585;
    line-height: 21.09px;
    margin-right: auto;
  }

  .modal-footer {
    border: 0px;
  }

  .text-center {
    font-family: Roboto;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.09px;
  }

  .modal-open {
    color: #000;
  }

  .btn-close {
    color: #1D3161;
    opacity: 1;
  }
}

.section.page_not_found {
  min-height: calc(100vh - 107px);
  overflow: auto;
  background-color: #E2E8F0;
  padding: 30px 38px 37px 64px;
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  @media (max-width:767px) {
    padding: 10px 15px 20px;
    min-height: 100%;
  }

  .btn.btn-primary {
    min-width: 320px;
    height: 66px;
    border-radius: 10px;
    background: #00B296;
    border: 0px;
    outline: 0px;
    font-family: Roboto;
    font-size: 24px;
    font-weight: 700;
    line-height: 28.13px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 5px;

    @media (max-width:767px) {
      font-size: 19px;
      height: 60px;
      min-width: max-content;
      padding-left: 30px;
      padding-right: 30px;

    }
  }

  .wrapper-nfunt {
    min-height: auto;

    h1 {
      @media (max-width:767px) {
        font-size: 80px;
      }
    }
  }

  .bg_image {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    object-fit: contain;
    object-position: center left;
    opacity: 0.2;
  }
}

.position_relative {
  position: relative;
}

.file_upload_popup {
  .modal-dialog.modal-dialog-centered {
    max-width: 1141px;
  }

  * {
    font-family: Roboto;
  }

  .modal-content {
    border-radius: 20px;
    overflow: hidden;
    padding: 0px;
    border: 0px;

    .container {
      padding: 0px;
      width: 100%;
      max-width: 100%;
    }

    .modal-header {
      padding-left: 15px;
      padding-top: 20px;
      padding-bottom: 30px;
      padding-right: 15px;
      background-color: #1D3161;
      color: #fff;
      text-align: center;
      justify-content: center;

      .h4 {
        font-family: Roboto;
        font-size: 24px;
        font-weight: 700;
        line-height: 28.13px;
        text-align: center;
      }
    }

    .modal-body {
      padding: 50px 50px 23px;

      @media (max-width:767px) {
        padding: 30px 15px;
      }

      .firstRow.row {
        margin-bottom: 30px;

        .firstCol .colWrapper,
        .secondCol .colWrapper {
          position: relative;
          padding: 16px 32px 16px 32px;
          border: 1px solid #E2E8F0;
          display: flex;
          align-items: center;
          border-radius: 8px;
          flex-direction: column;
          gap: 12px;
          border: 1px dashed #A7A7A7;
          cursor: pointer;
          text-align: center;
          height: 100%;
        }
      }

      .secondRow.row {
        .dragFileWrapper {
          height: 384px;
          border-radius: 40px;
          background: #F4F6FB;
          border: 1px dashed#A7A7A7;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          width: 100%;
          margin: 0px;
          text-align: center;
          gap: 0px;
          margin-bottom: 50px;

          @media (max-width:767px) {
            height: 320px
          }

          .upload_image {
            width: 130px !important;
            height: 140px;
            margin-top: auto;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;

            @media (max-width:767px) {
              width: 55px !important;
              height: auto;
            }
          }

          .textWrapper {
            display: flex;
            align-items: center;
            width: 100%;
            flex-wrap: wrap;
            justify-content: center;
            margin-bottom: auto;

            .draganddrop {
              font-family: Roboto;
              font-size: 45px;
              font-weight: 400;
              line-height: 52.73px;
              text-align: left;
              color: #7D7D7D;
              width: 100%;
              text-align: center;

              @media (max-width:767px) {
                font-size: 32px;
                line-height: 40px;
              }
            }

            .or {
              font-family: Roboto;
              font-size: 30px;
              font-weight: 400;
              line-height: 35.16px;
              text-align: center;
              text-decoration: unset;
              width: 100%;
              color: #7D7D7D;

              @media (max-width:767px) {
                font-size: 22px;
                line-height: 27.16px
              }
            }

            label[for="fileInput"] {
              font-family: Roboto;
              font-size: 25px;
              font-weight: 600;
              line-height: 23px;
              color: #FFFFFF;
              min-width: 210px;
              height: 61px;
              border-radius: 10px;
              background: #00B296;
              box-shadow: 0px 4px 24px 0px #0000000A;
              margin-top: 15px;
              padding: 15px 20px;
              text-align: center;
              cursor: pointer;

              @media (max-width:767px) {

                font-size: 19px;
                line-height: 11px;
                max-width: 210px;
                height: auto;
                padding: 11px 28px;

              }
            }

            label[for="fileInput"] span {
              margin: 0px !important;
              font-weight: inherit !important;
              text-decoration: unset !important;
              line-height: 28px;
            }
          }
        }


      }

      .thirdRow {
        @media (max-width:991px) {
          gap: 20px;

          .col {
            flex: 0 0 100%;
          }
        }

        .fileUploadedWrapper {
          display: flex;
          flex-wrap: wrap;



          .file_name {
            font-family: Roboto;
            font-size: 24px;
            font-weight: 400;
            line-height: 28.13px;
            text-align: left;
            color: #7D7D7D;
          }

          .leftSide {
            flex: 1 !important;
            word-break: break-all;
          }

          .rightSide {
            width: 34px;
            text-align: center;
            margin-left: 20px;

            img {
              object-fit: contain;
              width: 34px;

              &.loadingIcon {

                animation: 2s rotate360 infinite linear;
              }
            }
          }
        }

        .btnWrapper {
          text-align: right;
          margin-top: 0px;
          display: flex;
          justify-content: flex-end;

          button.done {
            font-family: Roboto;
            font-size: 24px;
            font-weight: 700;
            line-height: 28.13px;
            text-align: center;
            color: #FFFFFF;
            width: 221.79px;
            height: 66px;
            border-radius: 10px;
            background: #1D3161;

            svg {
              stroke: #fff;
            }

            @media (max-width:767px) {
              font-size: 18px;
              line-height: 21.13px;
              height: 60px;
            }
          }

          button.close {
            font-family: Roboto;
            font-size: 24px;
            font-weight: 700;
            line-height: 28.13px;
            text-align: center;
            color: #4F4F4F;
            width: 221.79px;
            height: 66px;
            border-radius: 10px;
            border: 1px solid #858585;
            background-color: transparent;
            margin-right: 24px;

            @media (max-width:767px) {
              font-size: 18px;
              line-height: 21.13px;
              height: 60px;
            }
          }
        }
      }
    }

  }
}

@keyframes rotate360 {
  to {
    transform: rotate(360deg);
  }
}

.extractionPdfWrapper {
  height: calc(100vh - 107px);
  background-color: #E2E8F0;
  padding: 30px 38px 37px 64px;
  width: 100%;

  @media (max-width:991px) {
    height: max-content;
  }

  @media (max-width:767px) {
    padding: 10px 15px 20px;

  }

  .pdfViewerWrapper {
    height: 100%;

    @media (max-width:991px) {
      margin-bottom: 30px;
    }
  }

  .row {
    height: 100%;

    .pdfViewerWrapper {
      height: 100%;
      overflow: hidden;
    }

    .react-pdf__Document.testpdf {
      height: calc(100% - 81.08333px);
      overflow: auto;
      background-color: #fff;

      &::-webkit-scrollbar {
        height: 5px;
        width: 5px;
        background: #dedede;
      }

      &::-webkit-scrollbar-thumb {
        background: #1d3161;
        -webkit-border-radius: 1ex;
        -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
      }

      &::-webkit-scrollbar-corner {
        background: #dedede;
        height: 30px;
        margin-right: 10px;
      }

      @media (max-width:991px) {
        height: 50vh;
      }
    }
  }

  .pdfFunctionWrapper {
    background-color: #1D3161;
    border: 0px;
    font-family: Roboto;
    font-size: 18px;
    font-weight: 600;
    line-height: 21.09px;
    height: 81.08333px;
    border-radius: 20px 20px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;

    svg,
    img {
      color: #fff;
      font-size: 28px;
      cursor: pointer;
    }

    .pageNavigationWrapper button {
      width: 28px !important;
      height: 28px !important;
      border-radius: 6px;
      background-color: #fff;
      font-family: Roboto;
      font-size: 15px;
      font-weight: 400;
      line-height: 17.58px;
      text-align: left;
      color: #636363;
      width: 12.53px;
      height: 24px;
      text-align: center;
      padding: 0px;
      border: none;

      &:first-child img {
        transform: rotate(90deg);
      }

      &[disabled=""] {
        opacity: 0.4;
      }

      &:first-child {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
      }

      &:last-child {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;

        img {
          transform: rotate(-90deg);
        }
      }
    }

  }

  .tableExtractionWrapper {
    height: 100%;
    overflow: hidden;

    .tablebox {
      overflow: auto;
      height: 100%;

      &::-webkit-scrollbar {
        height: 5px;
        width: 5px;
        background: #dedede;
      }

      &::-webkit-scrollbar-thumb {
        background: #1d3161;
        -webkit-border-radius: 1ex;
        -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
      }

      &::-webkit-scrollbar-corner {
        background: #dedede;
        height: 30px;
        margin-right: 10px;
      }

      .footer_row {
        padding-top: 13px;
        padding-bottom: 13px;

        .row {
          padding: 3px 35px;

          &:hover {
            background-color: transparent;
          }

          span {
            &.text {
              font-family: Roboto;
              font-size: 14px;
              font-weight: 600;
              line-height: 16.41px;
              text-align: left;
              color: #1D3161;
            }

            &.form-input-text {
              font-family: Roboto;
              font-size: 14px;
              font-weight: 400;
              line-height: 16.41px;
              width: 113px;
              display: block;
              text-align: right;
              padding: 0 10px;
              color: #122959;

              &.red {
                color: #D91010;
              }
            }


          }

          &.green {
            span {
              &.text {
                color: #00B296;
              }

              &.form-input-text {
                color: #00B296;
              }

              &.should_be_zero {
                color: #00B296;
                font-weight: 600;
              }
            }
          }

          &.red {
            span {
              &.text {
                color: #D91010;
              }

              &.form-input-text {
                color: #D91010;
              }

              &.should_be_zero {
                color: #D91010;
                font-weight: 600;
              }
            }
          }

          .col-7 {
            padding: 0px;
            display: flex;
          }
        }
      }
    }

    &::-webkit-scrollbar {
      height: 5px;
      width: 5px;
      background: #dedede;
    }

    &::-webkit-scrollbar-thumb {
      background: #1d3161;
      -webkit-border-radius: 1ex;
      -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
    }

    &::-webkit-scrollbar-corner {
      background: #dedede;
      height: 30px;
      margin-right: 10px;
    }

    .tableHeading {
      background-color: #1D3161;
      border: 0px;
      font-family: Roboto;
      font-size: 18px;
      font-weight: 600;
      line-height: 21.09px;
      height: 81.08333px;
      border-radius: 20px 20px 0px 0px;
      min-width: 757px;

      @media (max-width:400px) {
        height: auto;
      }

      .headingWrapper {
        display: flex;
        height: 100%;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        padding: 10px 35px;

        @media (max-width:400px) {
          flex-wrap: wrap;
        }

        select {
          width: 133px;
          height: 40px;
          border-radius: 10px;
          font-family: Roboto;
          font-size: 18px;
          font-weight: 700;
          line-height: 25.2px;
          text-align: left;
          color: #1D3161;
          background-color: #fff;
          border: 0px;
          padding: 8px 20px;
          background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAGCAYAAAD+Bd/7AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAB0SURBVHgBdY6xCYAwFESTH2xEIZ0iFtnAkImiC2QER3AEdRQnMCNopWUQSwmaIiBBf/W5d3ccolxS9HOOQQKgci5ZCJ2WAvTk2OaJFkLFWbWeuzYeRgAdsbbGPlGKpr2sHd3v4aIHg9+1zvQI3MPPYSwYfQPwjyOoB9i67AAAAABJRU5ErkJggg==);
          -moz-appearance: none;
          -webkit-appearance: none;
          appearance: none;
          background-repeat: no-repeat;
          background-position: right 21px center;
          background-size: auto;
          padding-right: 30px;
        }

        .extractTableDataButton {

          h5 {
            font-family: Roboto;
            font-size: 18px;
            font-weight: 700;
            line-height: 25.2px;
            text-align: left;
            color: #FFFFFF;
            margin-bottom: 0px;
            text-align: center;
          }
        }

        button {
          width: 133px;
          height: 40px;
          border-radius: 10px;
          background: #00B296;
          border: 0px;
          font-family: Roboto;
          font-size: 19px;
          font-weight: 600;
          line-height: 25.2px;
          text-align: center;
          color: #fff;

        }
      }
    }

    .extractionWrapper {
      height: calc(100% - 81.08333px);
      overflow: auto;
      background-color: #fff;
      min-width: 757px;

      &::-webkit-scrollbar {
        height: 5px;
        width: 5px;
        background: #dedede;
      }

      &::-webkit-scrollbar-thumb {
        background: #1d3161;
        -webkit-border-radius: 1ex;
        -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
      }

      &::-webkit-scrollbar-corner {
        background: #dedede;
        height: 30px;
        margin-right: 10px;
      }

      @media (max-width:991px) {
        height: 50vh;
      }

      .row {
        height: auto;
        align-items: center;
        padding: 10px 35px;
        margin: 0px;

        &:hover {
          background-color: #dee7ff;
        }

        .col-5 {
          padding: 0px;
          position: relative;
        }

        .col-4 {
          padding: 0px;
          width: 39%;
        }

        .col-3 {
          padding: 0px;
          width: 19%;
        }

        span {
          font-family: Roboto;
          font-size: 14px;
          font-weight: 400;
          line-height: 16.8px;
          text-align: left;
          color: #636363;
        }

        .form-input {
          width: 113px;
          height: 29px;
          border-radius: 5px;
          border: 1px solid #929395;
          font-family: Roboto;
          font-size: 14px;
          font-weight: 400;
          line-height: 16.8px;
          text-align: right;
          color: #00B296;
          -moz-appearance: none;
          -webkit-appearance: none;
          appearance: none;
          padding: 8px 10px;
          -moz-appearance: textfield;
          outline: unset;
          background-color: transparent;

          &.disabled {
            opacity: 0.5;
            pointer-events: none;
          }

          &.red {
            color: #D91010;
          }

          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            /* display: none; <- Crashes Chrome on hover */
            -webkit-appearance: none;
            margin: 0;
            /* <-- Apparently some margin are still there even though it's hidden */
          }

        }

        .btn.btn-primary {
          width: 104px;
          height: 34px;
          border-radius: 10px;
          background: #00B296;
          font-family: Roboto;
          font-size: 18px;
          font-weight: 700;
          line-height: 18px;
          text-align: center;
          color: #FFFFFF;
          border: 0px;

          &.disabled {
            opacity: 0.5;
            pointer-events: none;
          }
        }

        .edit_icon {
          filter: brightness(0) saturate(100%) invert(56%) sepia(34%) saturate(7333%) hue-rotate(140deg) brightness(97%) contrast(101%);
          width: 21px;
          margin-left: 10px;
          cursor: pointer;
        }

        .check_icon {
          margin-left: 5px;
        }
      }

    }
  }


  .full-width-pdf-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    max-width: 1141px;
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 40px;
    height: calc(100% - 80px);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    &:before {
      content: "";
      --bs-backdrop-zindex: 1050;
      --bs-backdrop-bg: #000;
      --bs-backdrop-opacity: 0.5;
      position: fixed;
      top: 0;
      left: 0;
      z-index: var(--bs-backdrop-zindex);
      width: 100vw;
      height: 100vh;
      background-color: var(--bs-backdrop-bg);
      opacity: var(--bs-backdrop-opacity);
      z-index: 0;
    }

    .react-pdf__Document.testpdf {
      height: calc(100% - 81.08333px);
      overflow: auto;
      background-color: #fff;
      z-index: 1;
      position: relative;
      margin-bottom: auto;
      width: 100%;

      @media (max-width:991px) {
        height: 50vh;

      }

      &::-webkit-scrollbar {
        height: 5px;
        width: 5px;
        background: #dedede;
      }

      &::-webkit-scrollbar-thumb {
        background: #1d3161;
        -webkit-border-radius: 1ex;
        -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
      }

      &::-webkit-scrollbar-corner {
        background: #dedede;
        height: 30px;
        margin-right: 10px;
      }

    }

    .pdfFunctionWrapper {
      z-index: 1;
      width: 100%;
      position: relative;
      margin-top: auto;
    }
  }

}

.row.user_box {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: left;
  gap: 15px;
  margin: 0px;

  .col {
    width: max-content;
    height: 75px;
    border-radius: 10px;
    font-family: Roboto;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.09px;
    text-align: left;
    color: #666666;
    border: 0px;
    background-color: #F5F5F5;
    padding: 10px 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: left;
    max-width: 25%;
    flex: auto;

    span {
      font-family: Roboto;
      font-size: 18px;
      font-weight: 400;
      line-height: 21.09px;
      text-align: left;
      float: left;
      color: #666666;

      &:not(.btn) {
        flex: 1;
      }
    }

  }
}

.button[type="submit"] {
  svg {
    stroke: #fff;
  }
}

.download-button {
  position: relative;

  .download-options {
    position: absolute;
    width: 100%;
    left: 0;
    list-style: none;
    top: 45px;
    z-index: 11;
    padding: 10px;
    border-radius: 10px;
    background: #FFFFFF;
    border: 1px solid #CDCDCD;
    box-shadow: 0px 4px 14px 0px #0000001A;

    .pointer {
      cursor: pointer;
      color: #00B296;
      margin: 10px;
    }
  }
}

.react-tooltip {
  z-index: 9999 !important;
}

.password-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;

  input {
    width: 100%;
    padding-right: 40px; // Make room for the icon
  }

  .password-visibility-toggle {
    position: absolute;
    right: 28px;
    background: none !important; // Override any existing background
    border: none !important; // Override any existing border
    padding: 0 !important; // Override any existing padding
    margin: 0 !important; // Override any existing margin
    min-width: unset !important; // Override any minimum width
    width: auto !important; // Override any fixed width
    height: auto !important; // Override any fixed height
    box-shadow: none !important; // Override any existing shadow
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover, &:focus {
      background: none !important;
      box-shadow: none !important;
      outline: none !important;
    }

    &:disabled {
      background: none !important;
      opacity: 0.5;
    }

    .eye-icon {
      width: 20px;
      height: 20px;
      color: #666;
      transition: color 0.2s ease;

      &:hover {
        color: #333;
      }
    }
  }
}
